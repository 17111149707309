<template>
    <div class="actciles-detail-content">
        <div class="actciles-detail-main">
            <div class="actciles-detail-info">
                <div class="actciles-detail-title" v-if="info.title">
                    <h4>{{ info.title }}</h4>
                    <div class="share-show">
                        <img class="wx" src="https://image.ysxxlm.cn/ysxxlm/pc/static/img/joinUs/weixin@2x.png"/>
                        <div class="vue-qr-box">
                            <vue-qr :text="downloadData.url" colorLight="#fff" :logoSrc="downloadData.icon + '?cache'" :logoScale="0.2" :size="200" :margin="20"></vue-qr>
                        </div>
                    </div>
                </div>
                <div class="actciles-detail-other" v-if="info.author">
                    <div class="actciles-detail-author">作者：{{ info.author }}</div>
                    <div class="actciles-detail-time">{{ info.createtime }}</div>
                    <div class="actciles-detail-label" v-if="info.Tag_arr && info.Tag_arr.length>0">
                        <span v-for="(item,index) in info.Tag_arr">{{ item.name }}</span>
                        <template v-if="info.custom_tags">
                            <span v-for="(item,index) in info.custom_tags.split(',')">{{ item }}</span>
                        </template>
                    </div>
                    <div class="actciles-detail-view">浏览 {{ info.click_nums }}</div>
                </div>

                <div class="actciles-detail-video" v-if="info.video_path">
                    <video id="player-info-id" style="width:100%; height:425px;" autoplay preload="auto" playsinline webkit-playsinline></video>
                </div>

                <div class="actciles-detail-html" v-html="info.content">前列腺术后夜尿症的诊治这个题目的核心是夜尿症，所以先来看一下什么是夜尿症？2018状患者首先排查是否存在夜间多尿，这需要通过排尿日记进行</div>

                <div class="actciles-detail-statement" v-if="info.title">
                    <div class="actciles-detail-statement-c">
                        声明：本内容仅代表嘉宾观点，不代表学习联盟平台立场。本内容仅供医学药学专业人士阅读，不构成实际治疗建议。
                        转载请后台联系授权，侵权必究！
                    </div>
                    <!--<div class="actciles-detail-statement-label">
                        <span>编辑：王靖</span><span class="actciles-detail-statement-label-shu">|</span><span>审核：李升</span>
                    </div>-->
                </div>
            </div>

            <div class="actciles-detail-right" ><!--:style="{top: rightW>0?100+'px':'',right: rightW+'px',position: rightW>0?'fixed':''}"-->
                <div class="actciles-detail-right-scroll" ><!--:style="{'overflow-y': rightW>0?'scroll':'','max-height': '700px'}"-->
                    <div class="actciles-detail-list" v-if="dylist.length>0">
                        <div class="actciles-detail-list-title">
                            <h3>调研问卷</h3>
                        </div>


                        <div class="info-dy-box">
                            <div class="info-dy" v-for="(item,index) in dylist" :key="item.id">
                                <div class="info-dy-title">{{ index+1 }}.<span v-html="item.question"></span></div>
                                <img :src="item.img" style="width: 100%" v-if="item.img"/>
                                <div class="info-dy-radio" v-if="item.type == 1">
                                    <el-radio-group v-model="item.value" style="width: 100%;">
                                        <el-radio :label="arr[0]" v-for="(arr,i) in item.answer" :key="i">
                                            {{ arr[1] }}
                                            <div v-if="i+1 == item.answer.length && item.has_other == 1 && item.value==arr[0]"><a-textarea class="info-dy-textarea" v-model="item.text" :style="{ width: 100, marginLeft: 10 }" /></div>
                                        </el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="info-dy-radio" v-if="item.type == 2">
                                    <el-checkbox-group v-model="item.value" style="width: 100%;">
                                        <el-checkbox :label="arr[0]" v-for="(arr,i) in item.answer" :key="i">
                                            {{ arr[1] }}
                                            <div v-if="i+1 == item.answer.length && item.has_other == 1 && item.value && item.value.indexOf(arr[0]) != '-1'"><a-textarea class="info-dy-textarea" v-model="item.text" :style="{ width: 100, marginLeft: 10 }" /></div>
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </div>
                                <div class="info-dy-radio" v-if="item.type == 3">
                                    <el-input
                                            class="info-dy-textarea"
                                            type="textarea"
                                            :rows="2"
                                            placeholder="请输入内容"
                                            v-model="item.value" :style="{ width: 100, marginLeft: 10 }" >
                                    </el-input>
                                </div>
                            </div>
                            <div style="text-align: center">
                                <el-button type="primary" block @click="confirmDy" style="width: 180px" v-if="record_id==''" round>
                                    提交
                                </el-button>
                                <template v-else>
                                    <p class="confirmDy-info" v-if="isBut"><i class="iconfont icon-tijiao"></i>提交成功</p>
                                    <p class="confirmDy-info" v-else>测评完成</p>
                                </template>
                            </div>

                        </div>


                    </div>

                    <div class="actciles-detail-list">
                        <div class="actciles-detail-list-title">
                            <h3>相关文章推荐</h3>
                            <span @click="refresh">换一批<i class="iconfont icon-shuaxin"></i></span>
                        </div>
                        <div class="actciles-detail-list-item" v-for="(item,index) in tjList" @click="toPath(item.id)" :key="index">
                            <div class="actciles-detail-list-item-img">
                                <img :src="item.img"/>
                            </div>
                            <div class="actciles-detail-list-item-info">
                                <div class="actciles-detail-list-item-title">{{ item.title }}</div>
                                <div class="actciles-detail-list-item-time">{{ item.createtime }}</div>
                                <div class="actciles-detail-list-item-view">浏览 {{ item.click_nums }}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'
    export default {
        components: {
            vueQr
        },
        data() {
            return {
                info: {},
                tcplayer: '',
                page: 1,
                tjList: [],

                dylist: [],
                dyid: '',
                record_id: '',
                isBut: false,
                rightW: 0,
                downloadData: {
                    url: `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/message/details?id=${this.$route.params.id}&share=1`,
                    icon: require('../../assets/img/logo.jpg')
                }
            }
        },
        watch:{
            $route(to,from){
                if(to.query.code) {
                    setTimeout( ()=> {
                        this.getDetail();
                    },1000)
                }
            }
        },
        mounted() {
            this.getDetail();
            this.getDetailTjList();
            this.getResearchRelation();
            /*window.addEventListener("scroll",this.scrollFun,true);*/
        },
        destroyed() {
            if(this.tcplayer) {
                this.tcplayer.dispose()
            }
        },
        methods: {
            toPath(id) {
                this.$router.replace({
                    path: `/actciles/${id}/detail`
                })
                this.downloadData.url = `https://miniapp.ysxxlm.com/ysxxlm/index.html#/pages/message/details?id=${id}&share=1`;
                this.getDetail();
                this.page = 1;
                this.getDetailTjList();
                this.getResearchRelation();
            },
            getDetail() {
                this.$axios.post(this.apiUrl.informationDetails, {
                    id: this.$route.params.id
                }).then(res => {
                    if(res.code == 1) {
                        this.info = res.data.Information;
						let metaInfo = {
							title: this.info.title,
							keywords: this.info.tags_keyword,
							description: this.info.title
						}
						this.$store.commit("CAHNGE_META_INFO", metaInfo)
                        let { video_id,psign,img  } = res.data.Information;
                        if(video_id != null) {
                            if(this.tcplayer){
                                this.tcplayer.loadVideoByID({fileID: video_id, appID: '1258924221', psign: psign});
                            }else {
                                this.$nextTick(()=> {
                                    this.play({
                                        video_id,
                                        psign,
                                        img
                                    })
                                })
                            }

                        }
                    }else if(res.code == 6){
                        this.$message.warning(res.msg);
                        this.$store.commit('wxLogin',true);
                    }else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            play(obj) {
                this.tcplayer = TCPlayer('player-info-id', {
                    fileID: obj.video_id,
                    appID: '1258924221',
                    psign: obj.psign,
                    poster: obj.img,
                    autoplay: true,
                    //其他参数请在开发文档中查看
                    plugins: {
                        ProgressMarker: true
                    }
                });
            },
            refresh() {
                this.page = this.page + 1;
                this.getDetailTjList();
            },
            getDetailTjList() {
                this.$axios.post(this.apiUrl.information_tj, {
                    page: this.page,
                    id: this.$route.params.id
                }).then(res => {
                    this.tjList = res.data.list;

                    if(this.page >= res.data.allpage) {
                        this.page = 0;
                    }
                })
            },
            getResearchRelation() {
                this.$axios.post(this.apiUrl.researchRelation, {
                    id: this.$route.params.id,
                    type: 2
                }).then(res => {
                    if(res.code == 1) {
                        this.dylist.map(item => {
                            item.value = ''
                        })
                        this.dylist = res.data.question;
                        this.dyid = res.data.id;
                        this.record_id = res.data.record_id;
                    }

                })
            },
            confirmDy() {

                let answer= []

                for(let i=0;i<this.dylist.length;i++) {
                    let arr = {
                        question_id: '', // 问题id
                        type: '', //问题类型 1为单选 2为多选 3为问答 4为程度题',
                        answer_ids: '', // 单选多选的答案
                        answer_num: '', // 程度题的答案
                        answer_other: '', // 当用户选择其他的文本
                        answer_text: '' // 填空简答题的答案
                    }
                    arr.question_id = this.dylist[i].id;
                    arr.type = this.dylist[i].type;
                    if(this.dylist[i].type == 1) {
                        arr.answer_ids = this.dylist[i].value
                    }
                    if(this.dylist[i].type == 2) {
                        arr.answer_ids = this.dylist[i].value.join()
                    }
                    if(this.dylist[i].type == 3) {
                        arr.answer_text = this.dylist[i].value
                    }

                    if(this.dylist[i].type == 1 && this.dylist[i].has_other == 1 && this.dylist[i].value == this.dylist[i].answer[this.dylist[i].answer.length-1][0]) {
                        arr.answer_other = this.dylist[i].text
                    }
                    if(this.dylist[i].type == 2 && this.dylist[i].has_other == 1 && this.dylist[i].value.indexOf(this.dylist[i].answer[this.dylist[i].answer.length-1][0]) != '-1') {
                        arr.answer_other = this.dylist[i].text
                    }
                    answer.push(arr)
                }
                console.log(answer)
                this.$axios.post(this.apiUrl.researchRelation_form, {
                    research_id: this.dyid,
                    answer:JSON.stringify(answer)
                }).then(res => {
                    if(res.code ==1) {
                        this.isBut = true;
                        setTimeout(() => {
                            this.isBut = false
                        },3000)
                        this.$message.success("提交成功");
                        this.getResearchRelation();
                    }else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            scrollFun() {
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollTop>80) {
                    this.loadFixedRight()
                }else {
                    this.rightW = 0
                }
            },
            loadFixedRight() {
                window.screenWidth = document.body.clientWidth;
                let screenWidth = window.screenWidth;
                let boxWidth = 1200;
                let rightW = (screenWidth-boxWidth)/2;
                this.rightW = rightW;
            }
        }
    }
</script>

<style scoped>

</style>
